import axiosCustom from './customAxios';
import {
  LoginPayload,
  RegistrationRequest,
  GoogleLoginPayload,
  FacebookLoginPayload,
} from './models';
import { ResetPasswordConfirmRequest } from './models/resetPasswordConfirm';
import { SetPasswordRequest } from './models/setPassword';
import { getProfileAndTrackUser, trackUserId } from '../utils';
import {
  trackLogoutInSolve,
  trackProfileInSolve,
} from '../utils/solve_analytics';

const END_POINTS = {
  login: '/login/',
  logout: 'rest-auth/logout/',
  register: '/rest-auth/registration/',
  resetPassword: '/rest-auth/password/reset/',
  googleLogin: '/social-auth/google-oauth2/',
  facebookLogin: '/social-auth/facebook/',
  resetPasswordConfirm: '/rest-auth/password/reset/confirm/',
  setPassword: '/set-password/',
};

const trackUser = () => {
  // Track user during an active session
  const userID = window.localStorage.getItem('userID');
  userID ? trackUserId() : getProfileAndTrackUser();
};

export const isBrowser = () => typeof window !== 'undefined';

export const isLoggedIn = (): boolean => {
  if (isBrowser()) {
    const token = window.localStorage.getItem('token');
    return !!token;
  }
  return false;
};

export const logout = (callback: { (): Promise<void>; (): void }) => {
  logoutUser();
  trackLogoutInSolve();
  isBrowser() && window.localStorage.removeItem('token');
  isBrowser() && window.localStorage.removeItem('userID');
  //TODO Add google / facebook logout
  callback();
};

export const loginUser = async (body: LoginPayload) => {
  const { data, error } = await axiosCustom.post(END_POINTS.login, body);
  if (error) {
    throw error;
  } else {
    trackProfileInSolve(data);
    isBrowser() && window.localStorage.setItem('token', data.key);
    trackUser();
    return data.key;
  }
};

// export const getTokenForGoogleSignin = async (
//   body: GoogleLoginPayload,
// ): Promise<string | null> => {
//   const { data, error } = await axiosCustom.post(END_POINTS.googleLogin, body);
//   if (error) {
//     throw error;
//   }
//   return data.key;
// };

export const loginUserWithGoogle = async (body: GoogleLoginPayload) => {
  const { data, error } = await axiosCustom.post(END_POINTS.googleLogin, body);
  if (error) {
    throw error;
  } else {
    trackProfileInSolve(data);
    isBrowser() && window.localStorage.setItem('token', data.key);
    trackUser();
    return data.key;
  }
};

// export const getTokenForFacebookSignin = async (
//   body: FacebookLoginPayload,
// ): Promise<string | null> => {
//   const { data, error } = await axiosCustom.post(
//     END_POINTS.facebookLogin,
//     body,
//   );
//   if (error) {
//     throw error;
//   }
//   return data.key;
// };

export const loginUserWithFacebook = async (body: FacebookLoginPayload) => {
  const { data, error } = await axiosCustom.post(
    END_POINTS.facebookLogin,
    body,
  );
  if (error) {
    throw error;
  } else {
    trackProfileInSolve(data);
    isBrowser() && window.localStorage.setItem('token', data.key);
    trackUser();
    return data.key;
  }
};

export const logoutUser = async () => {
  const { data, error } = await axiosCustom.post(END_POINTS.logout);
};

export const resetPassword = async (email: string) => {
  const { data, error } = await axiosCustom.post(END_POINTS.resetPassword, {
    email: email?.trim(),
  });

  if (error) {
    const isUserFound =
      error?.at(0)?.email?.at(0) === 'No user with this email address';
    return {
      isUserFound: !isUserFound,
      error: isUserFound ? 'No user with this email address' : '',
    };
  } else {
    return {
      isUserFound: true,
    };
  }
};

export const resetPasswordConfirm = async (
  payload: ResetPasswordConfirmRequest,
) => {
  const { data, error } = await axiosCustom.post(
    END_POINTS.resetPasswordConfirm,
    payload,
  );

  if (error) {
    throw error;
  }
  return data;
};

export const setPassword = async (payload: SetPasswordRequest) => {
  const { data, error } = await axiosCustom.post(
    END_POINTS.setPassword,
    payload,
  );

  if (error) {
    throw error;
  }
  return data;
};

export const register = async (payload: RegistrationRequest) => {
  const { data, error } = await axiosCustom.post(END_POINTS.register, payload);

  if (error) {
    throw new Error(error[0]);
  }
  trackProfileInSolve(data);
  isBrowser() && window.localStorage.setItem('token', data.key);
  trackUser();
  return data.key;
};
