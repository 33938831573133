import React, { useState, useEffect } from 'react';
import { isBrowser } from '../../utils';
import { isLoggedIn } from '../../services/auth.api';

export default () => {
  useEffect(() => {
    if (isLoggedIn()) {
      isBrowser() && window.location.replace('/account/dashboard/');
    } else {
      isBrowser() && window.location.replace('/login');
    }
  }, []);

  return null;
};
